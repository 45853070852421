export const OPEN_DIALOGUE = "OPEN_DIALOGUE";
export const CLOSE_DIALOGUE = "CLOSE_DIALOGUE";

export const LOADER_OPEN = "LOADER_OPEN";
export const CLOSE_LOADER = "CLOSE_LOADER";

export const OPEN_NOTIFICATION_DIALOGUE = "OPEN_NOTIFICATION_DIALOGUE";
export const CLOSE_NOTIFICATION_DIALOGUE = "CLOSE_NOTIFICATION_DIALOGUE";

export const UPLOAD_FILE_AWS = "UPLOAD_FILE_AWS";
