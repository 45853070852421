import axios from "axios";
import * as ActionType from "./setting.type";
import { setToast } from "../../../util/toast";
import { apiInstanceFetch } from "../../../util/api";
import { baseURL } from "../../../util/config";
export const getSettingApi = () => (dispatch) => {
  axios
    .get(`${baseURL}admin/setting?settingId=64d235999aa34a6e0afd19c2`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_SETTING_DATA,
        payload: res.data.setting,
      });
    })
    .catch((error) => console.error(error));
};

export const editSetting = (id, data) => (dispatch) => {
  axios
    .patch(`${baseURL}admin/setting/update?settingId=${id}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.EDIT_SETTING,
          payload: res.data.setting,
        });
        setToast("success", "Setting Update SuccessFully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const switchApi = (id, type, data) => (dispatch) => {
  axios
    .patch(
      `${baseURL}admin/setting/handleSwitch?settingId=${id}&type=${type}`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.SWITCH_SETTING,
          payload: res.data.setting,
        });
        setToast(
          "success",
          `${data === false ? type + " " + "enable " : type + " " + "disable"}`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const getWithdrawalApi = () => (dispatch) => {
  axios
    .get(`${baseURL}admin/withdraw`)
    .then((res) => {
      dispatch({ type: ActionType.GET_WITHDRAWAL, payload: res.data.withdraw });
    })
    .catch((error) => console.error(error));
};
export const deleteWithdrawalApi = (withdrawId) => (dispatch) => {
  axios
    .delete(`${baseURL}admin/withdraw/delete?withdrawId=${withdrawId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_WITHDRAW,
          payload: { id: withdrawId },
        });
        setToast("success", "Withdraw Method Delete SuccessFully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.error(error));
};

export const addPaymentGateway = (formData) => (dispatch) => {
  axios
    .post(`${baseURL}admin/withdraw/create`, formData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: ActionType.ADD_PAYMENT_GATEWAY,
          payload: res.data.withdraw,
        });
        setToast("success", "Payment Gateway Created Successfully !");
      }
    })
    .catch((error) => console.error(error));
};

export const editPaymentGateway = (id, data) => (dispatch) => {
  axios
    .patch(`${baseURL}admin/withdraw/update?withdrawId=${id}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.EDIT_PAYMENT_GATEWAY,
          payload: { data: res.data.withdraw, editDataId: id },
        });
        setToast("success", "Payment Gateway Edit SuccessFully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const isActivePaymentGetWay = (id, data) => (dispatch) => {
  axios
    .patch(`${baseURL}admin/withdraw/handleSwitch?withdrawId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.ACTIVE_SWITCH_PAYMENT_GATEWAY,
          payload: { editData: res.data.withdraw, editId: id },
        });
        setToast(
          "success",
          data === true ? "Active SuccessFully" : "Disable SuccessFully"
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const getAdsApi = () => (dispatch) => {
  axios
    .get(`${baseURL}admin/advertise`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADS_DATA,
        payload: res?.data?.advertise,
      });
    })
    .catch((error) => console.error(error));
};

export const isAdsChange = (data, id) => (dispatch) => {
  axios
    .patch(`${baseURL}admin/advertise/handleSwitchForAd?adId=${id}`)
    .then((res) => {
      if (res) {
        dispatch({
          type: ActionType.IS_ADS_CHANGE,
          payload: res?.data?.advertise,
        });
        setToast(
          "success",
          data === true
            ? "GoogleAds Disable SuccessFully"
            : "GoogleAds Active SuccessFully"
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const adsApiData = (data, id) => (dispatch) => {
  axios
    .patch(`${baseURL}admin/advertise/update?adId=${id}`, data)
    .then((res) => {
      if (res) {
        dispatch({
          type: ActionType.ADS_API_DATA,
          payload: res?.data?.advertise,
        });
        setToast("success", "Google Ads Update Successfully");
      }
    })
    .catch((error) => console.error(error));
};
