import axios from "axios";
import * as ActionType from "./shorts.type";
import { setToast } from "../../../util/toast";
import { apiInstanceFetch } from "../../../util/api";
import { baseURL } from "../../../util/config";
export const getShortsApi =
  (type, start, limit, startDate, endDate) => (dispatch) => {
    axios
      .get(
        `${baseURL}admin/video/videosOrShorts?videoType=${type}&start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: ActionType.GET_SHORTS,
          payload: {
            shortsData: res.data.videosOrShorts,
            totalShorts: res.data.totalVideosOrShorts,
          },
        });
      })
      .catch((error) => console.error(error));
  };

export const createShort = (Data) => (dispatch) => {
  const formData = new FormData();
  formData.append("videoUrl", Data.videoUrl);
  formData.append("videoImage", Data.videoImage, "thumbnail.jpg");
  formData.append("title", Data.title);
  formData.append("description", Data.description);
  formData.append("hashTag", Data.hashTag);
  formData.append("latitude", Data.latitude);
  formData.append("location", Data.location);
  formData.append("longitude", Data.longitude);
  formData.append("scheduleTime", Data.scheduleTime);
  formData.append("scheduleType", Data.scheduleType);
  formData.append("userId", Data.userId);
  formData.append("videoTime", Data.videoTime);
  formData.append("videoType", Data.videoType);
  formData.append("visibilityType", Data.visibilityType);
  formData.append("channelId", Data.channelId);
  formData.append("audienceType", Data.audienceType);
  formData.append("commentType", Data.commentType);
  axios
    .post(`${baseURL}admin/video/uploadVideo`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.LOADING,
          payload: {
            loading: false,
          },
        });
        dispatch({
          type: ActionType.IMPORT_SHORT,
          payload: {
            data: res.data.videoSaved,
            totalNumber: res.data.totalVideos,
          },
        });
        setToast("success", "Short created Successfully !");
      }
    })
    .catch((error) => console.error(error));
};

export const editShort =
  (data, videoId, userId, channelIdFind, type, fullNameUser) => (dispatch) => {
    axios
      .patch(
        `${baseURL}admin/video/updateVideo?videoId=${videoId}&userId=${userId}&channelId=${channelIdFind}&videoType=${type}`,
        data
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: ActionType.EDIT_SHORT,
            payload: {
              data: res.data.video,
              videoId: videoId,
              fullName: fullNameUser,
            },
          });
          setToast(
            "success",
            `${
              type === 1 ? "Short Edit SuccessFully" : "Short Edit SuccessFully"
            }`
          );
        } else {
          setToast("error", res.data.message);
        }
      })
      .catch((error) => console.log("error", error.message));
  };

export const deleteShort = (id) => (dispatch) => {
  axios
    .delete(`${baseURL}admin/video/deleteVideo?videoId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ActionType.DELETE_SHORT, payload: { id: id } });
        setToast("success", "Short Delete SuccessFully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
