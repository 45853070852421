import axios from "axios";
import * as ActionType from "./dashboard.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../../util/toast";
import { baseURL, secretKey } from "../../../util/config";
import { apiInstanceFetch } from "../../../util/api";

export const getDashboardCount = (startDate, endDate) => (dispatch) => {
  axios
    .get(
      `${baseURL}admin/dashboard/dashboardCount?startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => {
      dispatch({
        type: ActionType.GET_DASHBOARD_COUNT,
        payload: res.data.dashboard,
      });
    })
    .catch((error) => console.error(error));
};

export const getDashboardUserChart =
  (startDate, endDate, type) => (dispatch) => {
    axios
      .get(
        `${baseURL}admin/dashboard/chartAnalytic?startDate=${startDate}&endDate=${endDate}&type=${type}`
      )
      .then((res) => {
        dispatch({ type: ActionType.GET_DASHBOARD_USER, payload: res.data });
      })
      .catch((error) => console.error(error));
  };

export const getChartAnalyticOfActiveUser =
  (startDate, endDate, type) => (dispatch) => {
    axios
      .get(
        `${baseURL}admin/dashboard/chartAnalyticOfactiveInactiveUser?startDate=${startDate}&endDate=${endDate}&type=${"activeUser"}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_DASHBOARD_ACTIVE_CHART,
          payload: res.data.chartAnalyticOfactiveUsers,
        });
      })
      .catch((error) => console.error(error));
  };
//total user
export const getDashboardHost = (startDate, endDate) => (dispatch) => {
  axios
    .get(
      `${baseURL}admin/dashboard/totalHostForAdminPenal?startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => {
      console.log("dashboard host ", res);
      dispatch({ type: ActionType.GET_DASHBOARD_HOST, payload: res.data.host });
    })
    .catch((error) => console.error(error));
};

export const getUserChart = (type, startDate, endDate) => (dispatch) => {
  axios
    .get(
      `${baseURL}admin/dashboard/analyticOfdashboardCount?startDate=2023-07-22&endDate=2023-07-22&type=Video`
    )
    .then((res) => {
      console.log("user chart", res);
      dispatch({ type: ActionType.GET__USER_CHART, payload: res.data.user });
    })
    .catch((error) => console.error(error));
};

export const getRevenueChart = (type, startDate, endDate) => (dispatch) => {
  axios
    .get(
      `${baseURL}admin/dashboard/chartAnalyticForPenal?startDate=${startDate}&endDate=${endDate}&type=${type}`
    )
    .then((res) => {
      console.log("revenue chart", res);
      dispatch({
        type: ActionType.GET_REVENUE_CHART,
        payload: res.data.revenue,
      });
    })
    .catch((error) => console.error(error));
};

export const getDashboardHostFetch = (startDate, endDate) => {
  return async (dispatch) => {
    const url = `${baseURL}admin/dashboard/totalHostForAdminPenal?startDate=${startDate}&endDate=${endDate}`;
    const requestOptions = {
      method: "GET",
      headers: { key: secretKey },
    };

    try {
      const response = await fetch(url, requestOptions);
      const res = await response.json();
      dispatch({
        type: ActionType.GET_DASHBOARD_HOST_FETCH,
        payload: res.data.host,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};
