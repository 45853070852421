import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { FEATURE } from "../store/video/video.type";
import { Checkbox } from "primereact/checkbox";

const FeatureToggle = ({
  row,
  updateFeature,
  getVideoApi,
  page,
  size,
  startDate,
  endDate,
}) => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(row?.feature ? row?.feature : false);
  const handleChange = (event) => {
    setChecked(!checked);
    const data = [{ uniqueVideoId: row?.uniqueVideoId, feature: !checked }];
    updateFeature(data, 1, page, size, startDate, endDate);
  };
  return <input type="checkbox" onClick={handleChange} checked={checked} />;
};
export default FeatureToggle;
